import React, { useEffect, useState } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import { Box, TextField, InputAdornment, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import ViewAssetsModal from "./ViewAssetsModal";
import './AssetManagement.scss';

const columnData = [
    {
        id: "s.no",
        title: 'S.No',
        minWidth: '80px',
        isSticky: false,
        alignItem: 'center'
    },
    {
        id: "employeeCode",
        title: 'Employee Code',
        minWidth: '150px',
        isSticky: false,
        alignItem: 'center'

    },
    {
        id: "employeeName",
        title: 'Employee Name',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'


    },
    {
        id: "emailID",
        title: 'Employee Email',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "countryCode",
        title: 'Country Code',
        minWidth: '140px',
        isSticky: false,
        alignItem: 'center'


    },
    {
        id: "mobile",
        title: 'Mobile Number',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'


    },
    {
        id: "designation",
        title: 'Designation',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "department",
        title: 'Department',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "managerName",
        title: 'Manager Name',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "managerEmail",
        title: 'ManagerEmail',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "companyName",
        title: 'Company Name',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'

    },
    {
        id: "workLocation",
        title: 'Work Location',
        minWidth: '200px',
        isSticky: false,
        alignItem: 'left'


    },

    // {
    //     id: "actions",
    //     title: 'Actions',
    //     minWidth: '200px',
    //     isSticky:true
    // }
]

const AssetManagement = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [search, setSearch] = useState("");

    const [isStatusModel, setIsStatusModel] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(null)


    const { fetchData } = useAxios();

    const tableRef = React.createRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        tableRef.current.scrollTop = 0;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        getEmployeeList();
    }, []);
    const getEmployeeList = async () => {
        const response = await fetchData({
            url: URLS.getEmpoyees,
            method: "get",
        });
        setEmployees(response?.data || []);
        setFilteredEmployees(response?.data || [])
    };

    // console.log(employees, "employees employees")
    const reloadEmployeesList = (event) => {
        event.preventDefault(); // Fixed typo here
        const searchItem = search?.trim()?.toLowerCase();
        const filteredData = employees?.filter(
            (employee) =>
                employee?.employeeCode?.toLowerCase()?.includes(searchItem) ||
                employee?.employeeName?.toLowerCase()?.includes(searchItem) ||
                employee?.emailID?.toLowerCase()?.includes(searchItem)
        );
        setFilteredEmployees(filteredData);
    };

    const modelHandle = () => {
        setIsStatusModel(!isStatusModel)
    }
    return (
        <div className="row mb-1 pb-1">
            <div className="col-sm-12  mb-2">
                <div className="d-flex flex-column flex-lg-row">
                    <h4 className="title me-lg-5">Employee's List</h4>
                    <Box width={"45%"} className="ms-lg-5">
                        <form onSubmit={reloadEmployeesList}>
                            <TextField
                                type="text"
                                id="outlined-basic"
                                label="Search"
                                variant="outlined"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value); reloadEmployeesList(e) }}
                                onBlur={(e) => { if (e.target.value === '') { reloadEmployeesList(e) } }}
                                name="search"
                                size="small"
                                fullWidth
                                className="bg-white"
                                autoComplete='off'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit" className="p-1">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            // borderColor: "#4BBFBF",
                                            padding: '10px'
                                        },
                                        "&:hover fieldset": {
                                            // borderColor: "#6AC082"
                                        },
                                        "&.Mui-focused fieldset": {
                                            // borderColor: "#6AC082",
                                            backgroundColor: 'transparent'
                                        },
                                        '& input': {
                                            backgroundColor: 'transparent',
                                            '&:autofill': {
                                                backgroundColor: 'transparent',
                                                WebkitBoxShadow: '0 0 0 1000px white inset',
                                            },
                                        },
                                    },
                                    width: "92%",
                                }}
                            />
                        </form>
                    </Box>
                </div>

            </div>
            <div className="col-sm-12  mb-1">
                <div className="card d-flex flex-column  pb-1" >
                    <TableContainer sx={{ maxHeight: 480, maxWidth: '100%' }} className='table-container-cls' ref={tableRef}>
                        <Table stickyHeader aria-label="sticky table" className='table-cls' >
                            <TableHead>
                                <TableRow className='py-1'>
                                    {columnData.map((column) => (
                                        <TableCell
                                            key={"column" + column?.id}
                                            align={column?.alignItem}
                                            // align="center"
                                            style={{ minWidth: column?.minWidth }}
                                        >
                                            {column?.title}
                                        </TableCell>
                                    ))}
                                    <TableCell key={'actions-key'} className="sticky_right_header_column">Assets</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredEmployees?.length > 0 && filteredEmployees
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className='py-2'>
                                            {columnData.map((column, i) => (
                                                <TableCell key={`${row[column?.id]}-${index}-${i}`} align={column?.alignItem}> {`${column?.id === 's.no' ? index + 1 : row[column?.id]} `} </TableCell>
                                            ))}
                                            <TableCell key={'actions-row-key+' + index} className="sticky_right_column"><Tooltip title='View'><IconButton className="actions-btn-cls" onClick={() => { setSelectedEmployee(row); modelHandle() }}><VisibilityIcon /></IconButton></Tooltip></TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
                        component="div"
                        count={filteredEmployees.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table-pagination-container'
                    />
                </div>
            </div>
            {isStatusModel && <ViewAssetsModal getEmployeeList={getEmployeeList} isStatusModel={isStatusModel} modelHandle={modelHandle} selectedEmployee={selectedEmployee} />}
        </div>
    )
}

export default AssetManagement