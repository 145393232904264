import React from 'react'

const UserAssetsDetails = ({ assets = [] }) => {
    return (
        <div className="card mb-3 skills">
            <div className="p-3 bg-white rounded header d-flex justify-content-between align-items-center">
                <p className="m-0 h5 d-inline-flex"> Assets</p>

            </div>
            {assets && assets?.length > 0 ? <div className="table-responsive ms-4 px-2">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Asset Name</th>
                            <th scope="col">Serial Number</th>
                            <th scope="col">Issued Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assets && assets?.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.item}</td>
                                    <td>{data.serial_no}</td>
                                    <td>{data.issued_date}</td>
                                </tr>)
                            )
                        }
                    </tbody>
                </table>
            </div> : <p className='text-center pb-3'> Currently No Assets Assigned To You!</p>}
        </div>
    )
}

export default UserAssetsDetails