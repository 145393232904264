import React, {useState} from "react";
import Posts from "./Posts";
import Users from "../../components/Social/Users";
import NewPost from "./NewPost";
import Advert from "./Advert";
import { Box } from "@mui/system";
import "./SocialFeed.scss";

const SocialFeed = () => {
  const [user2, setUser2] = useState(null);
  return (
    <Box>
      <Box
        width="100%"
        padding="2rem 6%"
        display="flex"
        gap="0.5rem"
        justifyContent="space-between"
        maxHeight={"100vh - 64px"}
      >
        <Box flexBasis="74%">
            <NewPost />
            <Posts user2={user2}/>
        </Box>
        <Box flexBasis="26%" overflow={"none"}>
            <Advert />
            <Users setUser2={setUser2}/>
        </Box>
      </Box>
    </Box>
  );
};

export default SocialFeed;
