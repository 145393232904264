import { Box,IconButton, Typography, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import axios from "axios";
import {
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ChatBubbleOutlineOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { URLS } from "../../helpers/URLS";
import Avatar from "@mui/material/Avatar";
import { lightBlue } from "@mui/material/colors";

const Posts = ({user2}) => {
  const { fetchData } = useAxios();
  const [user, setUser] = useState({});
  const [comments, setComments] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect (() => {
    const getUser = async () => {
      const { data } = await fetchData({url: URLS.user, method: "get"});
      setUser(data);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (!user2) {
    const getAllPosts = async () => {
      const { data } = await fetchData({url: URLS.getAllPosts, method: "get"});
      setPosts(data);
    };
    getAllPosts();
  } else {
    const getUserPosts = async () => {
      const { data } = await axios.get(`${URLS.getUserPosts}/${user2.employeeCode}`);
      setPosts(data);
    }
    getUserPosts();
  }
  }, []);

  const handleLikePress = async (likes, id) => {
    if (likes.includes(user?.employee.employeeCode)) {
      const { data } = await fetchData({
        url: URLS.unlikePost,
        method: "post",
        data: { postId: id },
      });
      setPosts(data);
    } else {
      const { data } = await fetchData({
        url: URLS.likePost,
        method: "post",
        data: { postId: id },
      });
      setPosts(data);
    }
  };

  
  return (
    <>
      {posts && posts.map((post) => ( 
      <Box
        padding="1.5rem 1.5rem 0.75rem 1.5rem"
        backgroundColor="white"
        borderRadius="0.75rem"
        m="2rem 0"
        key={post._id}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="0.5rem"
            marginBottom={"1rem"}
          >
           <Avatar
              sx={{ bgcolor: lightBlue[900], width: 55, height: 55 }}
              className="profile_img_letter"
            >
              <h1>{post.senderName.substring(0, 1)}</h1>
            </Avatar>
            <Typography sx={{ ml: "1rem" }}>{post.senderName}</Typography>
          </Box>
        </Box>
        <Divider />
        <Typography sx={{ mt: "1rem" }}>
          {post.postDescription}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="0.25rem"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="0.3rem"
            >
              <IconButton onClick={() => handleLikePress(post.likes, post._id)}>
                {post.likes.includes(user?.employee.employeeCode) ? (
                  <FavoriteOutlined sx={{ color: "red" }} />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
              <Typography>{post.likes.length}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="0.3rem"
            >
              <IconButton onClick={() => setComments(!comments)}>
                <ChatBubbleOutlineOutlined />
              </IconButton>
              <Typography>{post.comments.length}</Typography>
            </Box>
          </Box>
          <IconButton>
            <ShareOutlined />
          </IconButton>
        </Box>
        {comments && (
          <Box mt="0.5rem">
            <Box>
              <Divider />
              {post.comments.map((comment) => (
                <Typography sx={{ mt: "0.5rem" }}>
                  <b>{comment.name}</b> {comment.comment}
                </Typography>
              ))}
            </Box>
            <Divider />
          </Box>
        )}
      </Box>
      ))}
    </>
  );
};

export default Posts;
