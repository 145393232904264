import { Typography, Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import { lightBlue } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";

const Users = ({setUser2}) => {
  const [employees, setEmployees] = useState([]);
  const [user, setUser] = useState("");
  const { fetchData } = useAxios();

  useEffect(() => {
    const getUser = async () => {
      const response = await fetchData({
        url: URLS.user,
        method: "get",
      });
      setUser(response.data);
    };
    getUser();
  }, []);
  useEffect(() => {
    const employeeList = async () => {
      const response = await fetchData({
        url: URLS.getEmpoyees,
        method: "get",
      });
      setEmployees(response.data);
    };
    employeeList();
  }, []);

  return (
    <Box
      padding="1.5rem 1.5rem 0.75rem 1.5rem"
      borderRadius="0.75rem"
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      maxHeight="calc(60vh - 64px)"
      gap="1.5rem"
    >
      <Typography>Employee List</Typography>
      <Divider />
      <Box overflow="auto">
        {employees && user && employees.filter((employee) => employee?.employeeCode !== user.employee.employeeCode).map((employee) => (
          <Box
            key={employee._id}
            display="flex"
            alignItems="center"
            gap="1rem"
            marginBottom="1rem"
            onClick={() => setUser2(employee)}
            sx={{ cursor: 'pointer', borderBottom: '1px solid lightgray' }}
          >
            <Avatar
              sx={{ bgcolor: lightBlue[900], width: 55, height: 55 }}
              className="profile_img_letter"
            >
              {!employee.pictureURL&& <h1>{employee.employeeName.substring(0, 1)}</h1>}
              {employee.pictureURL && <img src={employee.pictureURL} alt="profile" className="profile_img" width="55px" height="55px"/>}
            </Avatar>

            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography 
                sx={{ ml: "1rem", '&:hover': { color: 'lightBlue[900]' } }}
              >
                {employee.employeeName}
              </Typography>
              <Typography sx={{ ml: "1rem" }} fontSize="0.75rem">
                {employee.designation}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Users;