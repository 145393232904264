import { Input, Box, Divider, Typography, IconButton, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useAxios from '../../helpers/Hooks/useAxios';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { URLS } from '../../helpers/URLS';
import { lightBlue } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';

const NewPost = () => {
  const { fetchData } = useAxios();
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [pictureURL, setPictureURL] = useState("");
  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [description, setDescription] = useState("");

  const handlePhotoUploadClick = () => {
    photoInputRef.current.click();
  };

  const handleVideoUploadClick = () => {
    videoInputRef.current.click();
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const getUserProfle = async () => {
      const resp = await fetchData({ url: URLS.user, method: 'get' });
      if (resp.status === 200) {
          setEmployeeCode(resp.data.employee.employeeCode);
          setEmployeeName(resp.data.employee.employeeName);
          setPictureURL(resp.data.employee.pictureURL);
      }
    }
    getUserProfle();
  }, []);

  const handlePost = async () => {
   
    const formData = new FormData();
    formData.append("senderId", employeeCode);
    formData.append("senderName", employeeName);
    formData.append("description", description);
    formData.append("image", photoInputRef.current.files[0]);
    formData.append("video", videoInputRef.current.files[0]);
    formData.append("attachment", fileInputRef.current.files[0]);
    console.log(formData , 'formData')
    const resp = await fetchData({
      url: URLS.createPost,
      method: "post" ,
      body: formData,
    });
   
    if (resp.status === 200) {
      console.log(resp , 'resp');
      setDescription("");
      photoInputRef.current.value = "";
      videoInputRef.current.value = "";
      fileInputRef.current.value = "";
    }
    else{
      console.log(resp.message);
    }

  };

  return (
    <>
      <Box
        padding="1.5rem 1.5rem 0.75rem 1.5rem"
        backgroundColor="white"
        borderRadius="0.75rem"
        m="2rem 0"
        textAlign={"center"}
      >
        <Typography sx={{ mt: "1rem" }}>New Post</Typography>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          marginTop={"0.75rem"}
          marginBottom={"0.75rem"}
        >
          <Avatar
              sx={{ bgcolor: lightBlue[900], width: 55, height: 55 }}
              className="profile_img_letter"
            >
              {!pictureURL && <h1>{employeeName.substring(0, 1)}</h1>}
              {pictureURL && <img src={pictureURL} alt="profile" className="profile_img" width="55px" height="55px"/>}
            </Avatar>
          <Input
            placeholder="What's on your mind?"
            fullWidth
            disableUnderline
            sx={{ ml: "1rem" }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={"0.5rem"}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="0.5rem">
            <input type="file" ref={photoInputRef} style={{ display: 'none' }} accept="image/*" />
            <IconButton onClick={handlePhotoUploadClick}>
              <AddPhotoAlternateOutlinedIcon />
            </IconButton>
            
            <input type="file" ref={videoInputRef} style={{ display: 'none' }} accept="video/*" />
            <IconButton onClick={handleVideoUploadClick}>
              <MovieCreationOutlinedIcon />
            </IconButton>

            <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
            <IconButton onClick={handleFileUploadClick}>
              <AttachFileIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="0.5rem">
            <Button variant="contained" color="primary" sx={{ ml: "1rem" }} onClick={handlePost}>Post</Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewPost;
