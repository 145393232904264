// import logo from './logo.svg';
import './App.scss';
import Home from './views/Home/Home';
import About from './views/About/About';
import Dashboard from './views/Dashboard/Dashboard';
import Login from './Login'
import Header from './components/Header';
import { LoaderProvider } from './Context/LoaderContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ManageProjects from './views/ManageProjects';
import ManageTeams from './views/manageTeams';
import WorkReport from './views/WorkReport';
import DailyStatus from './views/DailyStatus';
import ViewStatus from './views/ViewStatus';
import Recruiter from './views/Recruiter';
import JobJD from './views/JobJD';
import React, { Profiler } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CandidateDetails from './views/Recruiter/CandidateDetails';
import Interviewer from './views/Recruiter/interviewer';
import InterViewAsssignList from './views/InterViewAsssignList'
import ScheduleInterview from './views/Recruiter/ScheduleInterview';
import Leaves from './views/Leaves/Leaves';
import Approvals from './views/Leaves/Approvals';
import BasicTabs from './views/Recruiter/Main';
import Profile from './views/ProfilePage/Profile';
import SearchSkills from './views/SkillSearch/SearchSkills';
import ProjectReport from './views/ProjectReport/ProjectReport';
import AccountActivate from './views/Account-Activate'
import TimeSheets from './views/Timesheets';
import Chat from './views/Chat/Chat';
import SocialFeed from './views/SocialFeed/SocialFeed';
import AssetManagement from './views/AssetManagement/AssetManagement';
function App() {
  return (
    <LoaderProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover={false}
      />

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/AccountActivate' element={<AccountActivate />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path="/" element={<Header />}>
            <Route path='/dashBoard' element={<Dashboard />} />
            <Route path='/time-sheet' element={<TimeSheets />} />
            <Route path='/manage-projects' element={<ManageProjects />} />
            <Route path='/manage-team' element={<ManageTeams />} />
            <Route path='/work-report' element={<WorkReport />} />
            <Route path='/status-report' element={<DailyStatus />} />
            <Route path='/report-view/:parameter/:value' element={<ViewStatus />} />
            <Route path='/recruiter' element={<Recruiter />} />
            <Route path='/jobs' element={<JobJD />} />
            <Route path='/recruiter/list' element={<InterViewAsssignList />} />
            <Route path='/recruiter/candidatedetails/:candidateID' element={<CandidateDetails />} />
            <Route path='/recruiter/interviewer/:candidateID/:interViewID/:scheduledTo' element={<Interviewer />} />
            <Route path="/scheduleinterview" element={<ScheduleInterview />} />
            <Route path='/leaveRequests' element={<Leaves />} />
            <Route path='/approvals' element={<Approvals />} />
            <Route path='/pool' element={<BasicTabs />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/searchskill' element={<SearchSkills />} />
            <Route path='/project-report' element={<ProjectReport />} />
            <Route path='/chat' element={<Chat />} />
            <Route path='/social-feed' element={<SocialFeed />} />
            <Route path='/asset-management' element={<AssetManagement />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoaderProvider>
  );
}
export default App;
