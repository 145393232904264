import React, { useEffect, useState } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { URLS } from "../../helpers/URLS";
import axios from "axios";

const Messages = ({user2}) => {
  const { fetchData } = useAxios();
  const [chat, setChat] = useState(null);
  const [user, setUser] = useState(null);
  const [messageCount, setMessageCount] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getUser = async () => {
      const response = await fetchData({
        url: URLS.user,
        method: "get",
      });
      setUser(response.data);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (user === null) return;
    const getChat = async () => {
      const params = {
        user1Id: user.employee.employeeCode,
        user2Id: user2.employeeCode,
      };
      try {
        const response = await axios.get(URLS.getChat, { params })
        setChat(response.data);
      } catch (error) {
        const response = await fetchData({
          url: URLS.createChat,
          method: "post",
          body: params,
        });
        setChat(response.data);
      }
    };
    getChat();
  }, [user2, messageCount]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (chat === null) return;
    const body = {
      senderId: user.employee.employeeCode,
      receiverId: user2.employeeCode,
      message: e.target[0].value,
    };
    const response = await fetchData({
      url: URLS.sendMessage,
      method: "post",
      body,
    });
    setMessageCount(messageCount + 1);
    setMessage("");
  };


  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"flex-end"}
      flexGrow={1}
      padding="1.5rem 1.5rem 0.75rem 1.5rem"
      backgroundColor="white"
      borderRadius="0.75rem"
      maxHeight="calc(100vh - 64px)"
    >
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        flexDirection="row"
        marginTop={"0.75rem"}
        marginBottom={"0.75rem"}
      >
        <Typography sx={{ ml: "1rem" }}>Chat with {user2?.employeeName}</Typography>
      </Box>
      <Divider />
      <Box
        width="100%"
        flex={2}
        borderRadius="1rem"
        padding="1rem"
        overflow={"auto"}
        height="calc(100vh - 64px - 4rem)"
        maxHeight="400px"
        display="flex"
        flexDirection="column"
      >
        {chat && chat.messages.map((message) => (
          message.senderId === user.employee.employeeCode ? (
            <Box mb="0.5rem" display="flex" justifyContent={"flex-end"}>
              <Box
                bgcolor="blue"
                color="white"
                borderRadius="1rem"
                padding="0.5rem 1rem"
                display="inline-block"
                maxWidth="70%"
                overflowWrap="break-word"
              >
                {message.content}
              </Box>
            </Box>
          ) : (
            <Box mb="0.5rem" display="flex" justifyContent="flex-start">
              <Box
                bgcolor="purple"
                color="white"
                borderRadius="1rem"
                padding="0.5rem 1rem"
                display="inline-block"
                maxWidth="70%"
                overflowWrap="break-word"
              >
                {message.content}
              </Box>
            </Box>
          )
        ))}
        {!chat && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography>No messages yet</Typography>
            
          </Box>)}
      </Box>
      <form onSubmit={sendMessage}>
        <Box
          display="flex"
          alignItems="center"
          padding="0.5rem"
          bgcolor="neutral.light"
          border="1px solid rgba(0,0,0,0.1)"
          borderRadius="10px"
        >
          <Input
            fullWidth
            placeholder="Type your message here..."
            sx={{
              marginRight: "1rem",
            }}
            disableUnderline
            onChange= {(e) => setMessage(e.target.value)}
            value = {message}
          />
          <Button variant="contained" color="primary" type="submit">
            <SendOutlinedIcon />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Messages;
