import { Typography} from "@mui/material";
import { Box } from "@mui/system";


const AdvertWidget = () => {

  return (
    <Box  padding="1.5rem 1.5rem 0.75rem 1.5rem"
    backgroundColor="white"
    borderRadius="0.75rem"
    m="2rem 0">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={"black"} variant="h5" fontWeight="500">
          Sponsored
        </Typography>
        <Typography color={"gray"}>Create Ad</Typography>
      </Box>
      <img
        width="100%"
        height="auto"
        alt="advert"
        src= {`https://source.unsplash.com/random/1280x720?sig=${Math.floor(Math.random() * 100)}`}
        style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={"black"}>MikaCosmetics</Typography>
        <Typography color={"gray"}>mikacosmetics.com</Typography>
      </Box>
      <Typography color={"gray"} m="0.5rem 0">
        Your pathway to stunning and immaculate beauty and made sure your skin
        is exfoliating skin and shining like light.
      </Typography>
    </Box>
  );
};

export default AdvertWidget;
