import React, {useState} from 'react'
import { Box } from '@mui/system'
import Users from '../../components/Social/Users'
import Messages from './Messages';

const Chat = () => {
  const [user2, setUser2] = useState(null);
    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 64px)">
          <Box
            width="100%"
            padding="2rem 6%"
            display="flex"
            gap="0.5rem"
            justifyContent="space-between"
            maxHeight={"100vh - 64px"}
          >
            <Box flexBasis="26%" overflow={"auto"} flexGrow={1}>
                <Users setUser2={setUser2}/>
            </Box>
            <Box flexBasis="74%" flexGrow={1}>
                <Messages user2={user2}/>
            </Box>
          </Box>
        </Box>
      );
    };

export default Chat